<template>
  <div class="inner-layout">
    <div v-for="(item, index) in list" :key="`theme-name-${index}`">
      <div :class="['main-heading-group', {'mt130':index==0}, {'mt80': index>0}]">
        <div class="heading-3">{{ item.theName }}
          <div class="desc">{{ item.theSubName }}</div>
        </div>
        <div v-if="item.list.length > 3" class="right-link"><router-link :to="`/md/${item.idx}`">더보기 <span class="icon-arrow"></span></router-link></div>
      </div>

      <div class="item-container roll" style="padding-bottom: 0">
        <VueSlickCarousel v-if="item.list && item.list.length > 0" v-bind="mdSettings">
          <div v-for="(item2,index) in item.list" :item="item" :key="`md-${item2.boIdx}-${index}`"
               @mousedown="updateMousePosition($event,'down', 'md', item.idx, item2.boIdx)"
               @mouseup="updateMousePosition($event,'up', 'md', item.idx, item2.boIdx)"
               class="default-item">
            <div>
              <div class="item-img">
                <div class="thumb-img youtube-ratio"><image-alt :src="item2.thumbnail" altType="list"></image-alt></div>
              </div>
              <div class="item-con">
                <div class="item-title">{{ item2.title }}</div>
                <div class="item-info">
                  <span class="date">{{ $getDateFormat(item2.uploadDate, 'Y.m.d') }}</span>
                </div>
                <div class="item-info2">
                  <div class="profile-set">
                    <div class="profile-info">
                      <div><span class="nickname">{{ item.chanName }}</span></div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="hit"><span class="icon-img icon-eye"></span> {{ item2.view ? Number(item2.view).numberFormat() : 0 }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
  </div>
</div>
</template>

<script>
import carouselMixin from "@/mixins/carouselMixin"
import listMixin from "@/mixins/listMixin"
export default {
  name: "mdList",
  mixins: [carouselMixin, listMixin],
  data() {
    return {
      list: null
    }
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const { result, data } = await this.$api.getMdList()
      if (result === 'success') this.list = data
    },
    goDetail(target, id, index) {
      if (this.mousedownPositionX == this.mouseupPositionX && this.mousedownPositionY == this.mouseupPositionY) {
        this.setBeforeContent()
        this.$router.push(`/${target}/${id}/${index}`)
      }
    },
  }
}
</script>


